<template>
    <div id="chart-column">
        <apexchart ref="chart_column"  type="line" height="550" :options.sync="columnOptionsData" :series="columnSeriesData"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
    props: {
        columnSeriesData: Array,
        columnOptionsData: Object
    },
    components: {
        apexchart: VueApexCharts,
    },
    watch: {
        "$props.columnSeriesData"() {
            this.$refs.chart_column.updateOptions(this.columnOptionsData, false ,true);
        }
    },
    data() {
        return {
            
        }
    }   
}
</script>