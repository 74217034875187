<template>
    <div class="p-grid dashboard--view">
        <div class="p-col-12">
            <div class="card card-w-title">
                <div class="manager-title">Phần Mềm Quản Lý Vận Hành Kho</div>
                <div class="mb-5">
                    <div class="row mb-3">
                        <div class="col-12 col-lg-3">
                            <label class="p-col-fixed txt-left p-0">Thời gian báo cáo</label>
                            <div class="p-col px-0">
                                <div class="col-12 px-0">
                                    <Calendar :disabled="loading" v-model="dateReport" placeholder="DD/MM/YYYY - DD/MM/YYYY" class="w-100" appendTo="body" :showButtonBar="true" selectionMode="range" dateFormat="dd/mm/yy" :showIcon="true"/>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 col-lg-3">
                            <label class="p-col-fixed txt-left p-0">Công ty</label>
                            <div class="p-col pl-0">
                                <v-select :disabled="loading" title="Mã công ty" id= "a2" :filterable="false" :clearable="false" placeholder="Chọn" v-model="selCompany" :options="listCompany" label="code" @search="fetchOptionsCompany">
                                    <template #selected-option="{ code, name }">
                                        <em><b>{{code}}</b> - {{name}}</em>
                                    </template>
                                    <template #option="{ code, name }">
                                        <em><b>{{code}}</b> - {{name}}</em>
                                    </template>
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        Không có dữ liệu
                                    </span>
                                </v-select>
                                <input class="input_tmp_validator" id="company_id">
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-6 col-lg-2 p-2" v-for="(item, index) in reportBlock" :key="index" :class="loading ? 'dashboard-load-div' : ''">
                            <div class="report-box">
                                <div class="report-box-title">
                                    {{item.label}}
                                </div>
                                <div class="report-box-value">
                                    <ProgressSpinner v-if="loading"/>
                                    <span v-else>{{ $commonFuction.numberFormat(item.value, 0, '.', ',') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OperationReport :loading="loading" :funnelData="funnelSeriesData" :columnData="columnSeriesData" :columnLabel="columnLabelData"/>
            </div>
            
            <div class="card card-w-title">
                <div class="manager-title">THEO DÕI TÌNH TRẠNG ĐƠN HÀNG THEO KÊNH PHÂN PHỐI</div>
                <BaseDatatable
                    :key="componentKey"
                    :tableData="listData"
                    :model.sync="dataModel"
                    :options="options"
                    :filterDefault.sync="filters"
                    :getFilterOptions="getFilterOptions"
                    v-on:action-click="onActionClicked"
                    @onDataChange = 'DataOnChange'
                />
                <!-- :actions="getRowActions()" -->
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment-timezone";
import OperationReport from './operation';
import BaseDatatable from './list'
import ApiRepository from "@/core/ApiRepository";
import DataServices from '@/core/DataServices';
import AuthenticationApp from "@/core/AuthenticationApp";
export default {
    name: 'Dashboard',
    components: {
        OperationReport,
        BaseDatatable
    },
    data() {
        return {
            selCompany: null,
            listCompany: [],
            listCompanyAll: [],
            dateReport: null,
            filters : {},
            filterOptions: {},
            options: [],
            listData: [],
            modelName: '',
            dataModel: null,
            title: '',
            componentKey: 0,
            editId: null,
            displayDialog: false,
            editDialogKey: 0,
            dialogMode: 'edit',
            deleteId: null,
            deleteName: null,
            showDeleteDialog: false,
            whereKeyList:{
                where_key: {
                /*accounts: {
                    role: {_eq: "admin"},
                    deleted: {_eq: false}
                },*/
                deleted: {_eq: false}
                }
            },
            page_transaction:null,
            role: {
                view: false,
                add: false,
                edit: false,
                delete: false,
            },
            loading: false,
            time_zone_local : moment.tz.guess(true),
            reportBlock: [
                {
                    label: 'Tổng đơn hàng',
                    value: 0
                },
                {
                    label: 'Đơn mới',
                    value: 0
                },
                {
                    label: 'Đã giao hàng thành công',
                    value: 0
                },
                {
                    label: 'Đã giao hàng',
                    value: 0
                },
                {
                    label: 'Đang giao hàng',
                    value: 0
                },
                {
                    label: 'Đang xử lý',
                    value: 0
                }
            ],
            funnelSeriesDataTmp: [{data: [{x: 'Tổng đơn xuất Kho', y: 0}, {x: 'Nhận đơn hàng và bắt đầu soạn hàng', y: 0},  {x: 'Niêm phong kiện và chuyển khu chờ xuất', y: 0}, {x: 'Điều phối giao hàng', y: 0}, {x: 'Xuất hàng thực tế', y: 0}, {x: 'Đang giao hàng', y:0}, {x: 'Đã giao hàng', y: 0}, {x: 'Giao thành công & Đủ bộ HSCT', y: 0, }]}],
            funnelSeriesData: this.funnelSeriesDataTmp,
            columnSeriesDataTmp: [{
                name: 'Số lượng',
                type: 'column',
                data: [0, 0, 0, 0, 0, 0 ,0, 0, 0, 0, 0, 0]
            }, {
                name: 'Khung thời gian',
                type: 'line',
                data: [0, 0, 0, 0, 0, 0 ,0, 0, 0, 0, 0, 0]
            }],
            columnSeriesData: this.columnSeriesDataTmp,
            columnLabelDataTmp: [['Giao', 'ngay'], ['1-3', 'ngày'], ['4-6', 'ngày'], ['>20', 'ngày'], ['10-15','ngày'], ['16-20', 'ngày'], ['7-9', 'ngày'], ['Online', 'lấy tại', 'kho'], ['Tự lấy', 'hàng'], ['Chưa', 'giao', 'hàng'], ['Chưa', 'có ngày', 'thanh toán'], ['Chuyển', 'kho']],
            columnLabelData: this.columnLabelData
        }
    },
    // async mounted () {
    //     await this.initParams();
    // },
    async created() {
        await this.initParams();
        await this.getListCompany();
        this.$set(this, 'dateReport', [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]);
        this.modelName = this.$route.meta.model;
        this.dataModel = DataServices.getModel(this.modelName);
        //console.log('this.dataModel:',this.dataModel);
        this.title = this.dataModel.name
        // this.$store.commit('setLoading', true);
        if(this.$route.params.reload) {
            window.location.reload();
        }
        // var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_PRODUCT);
        // if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
        // this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
        // }
        // // console.log("arr_role:" ,arr_role)
        // this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
        // this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
        // this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;

        // var arr_role_clubs = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_CLUBS);
        // // console.log('role_clubrole_club',arr_role_clubs)
        // if (arr_role_clubs.indexOf(this.$constants.ROLE.VIEW) < 0){
        //   this.view_club = false;
        // }else{
        //   this.view_club = true;
        // }
        // console.log('this.view',this.view_club)
    },
    watch: {
        '$route': 'initParams',
        "dateReport"() {
            // this.filterHandle();
            if (this.dateReport) {
                if (!this.$commonFuction.isEmpty(this.dateReport[0]) && !this.$commonFuction.isEmpty(this.dateReport[1])) {
                    console.log("this.dateReportthis.dateReportthis.dateReport", this.dateReport);
                    this.$set(this.filters, 'date_report', this.dateReport);
                    this.filterHandle();
                }
            } else {
                console.log("this.dateReportthis.dateReportthis.dateReport", this.dateReport);
                this.$delete(this.filters, 'date_report');
                this.filterHandle();
            }
        },
        "selCompany"() {
            // console.log("this.selCompanythis.selCompany", this.selCompany);
            // this.filterHandle();
            if (this.selCompany?.id) {
                this.$set(this.filters, 'company_id', this.selCompany?.id);
                this.filterHandle();
            } else {
                this.$delete(this.filters, 'company_id');
                this.filterHandle();
            }
        }
    },
    methods:{
        async filterHandle() {
            if (!this.loading) {
                await this.loadData();
            }
            // if (this.dateReport) {
            //     if (!this.$commonFuction.isEmpty(this.dateReport[0]) && !this.$commonFuction.isEmpty(this.dateReport[1])) {
            //         console.log("this.dateReportthis.dateReportthis.dateReport", this.dateReport);
            //         this.$set(this.filters, 'date_report', this.dateReport);
            //         this.loadData();
            //     }
            // } else {
            //     console.log("this.dateReportthis.dateReportthis.dateReport", this.dateReport);
            //     this.$delete(this.filters, 'date_report');
            //     this.loadData();
            // }
            // if (this.selCompany?.id) {
            //     this.$set(this.filters, 'company_id', this.selCompany?.id);
            //     this.loadData();
            // } else {
            //     this.$delete(this.filters, 'company_id');
            //     this.loadData();
            // }
            // && ((!this.$commonFuction.isEmpty(this.dateReport[0]) && !this.$commonFuction.isEmpty(this.dateReport[1])) || (this.$commonFuction.isEmpty(this.dateReport[0]) && this.$commonFuction.isEmpty(this.dateReport[1])))
            
        },
        async getListCompany(){
          var whereAttach ={}
          var listDisplayCompany = AuthenticationApp.getListCompany();
          if (listDisplayCompany != null) {
            //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
            whereAttach.id =  {_in: listDisplayCompany};
          }
          var list =await this.$CoreService.getCompanyFilter(whereAttach);
            var list_company = [];
            // console.log("list===",list);
            for (let i = 0; i < list.length; i++) {
                const e = list[i];
                list_company.push({id : e.id, name :e.name, code: e.code});
            }
            this.listCompany = list_company;
            this.listCompanyAll = list_company;
            if (this.listCompanyAll.length > 0){
                this.selCompany = this.listCompanyAll[0]
            }
        },
            
        async fetchOptionsCompany(search, loading){
            console.log(loading);
            if(search !== '' && search !== null){
                var where_key = {
                    deleted : {_eq : false},
                    _or: {
                        code:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"}
                    }
                };
                var listDisplayCompany = AuthenticationApp.getListCompany();
                if (listDisplayCompany != null)
                {
                    where_key.id = {_in: listDisplayCompany};
                }
                let {data} = await this.$apollo.query({
                query: DataServices.getList('company',{"fields" : `id code name`}),
                variables: {
                    where_key: where_key,
                    orderBy:[{created_at : 'desc'}]
                },
                fetchPolicy: "network-only"
                })
                var list = data[Object.keys(data)];
                var list_company = [];
                // console.log("list===",list);
                for (let i = 0; i < list.length; i++) {
                const e = list[i];
                list_company.push({id : e.id, name :e.name, code: e.code});
                }
                this.listCompany = list_company;
            }else{
                // cosnole.log("dasdasd")
                this.listCompany = this.listCompanyAll
            }
        },
        async initParams() {
            this.$store.commit('setLoading', true);
            this.modelName = this.$route.meta.model;
            this.dataModel = DataServices.getModel(this.modelName);
            this.title = this.dataModel.label;
            await this.callbackOptions();
        },
        async callbackOptions() {
            var fields = this.dataModel.getDisplayFields();
            for (let index = 0; index < fields.length; index++) {
                var attributes = [];
                const element = fields[index];
                if (element.models !== undefined && element.models !== null) {
                // console.log("SDFGHJK",element)
                var optionsData = [];
                attributes.push(element.models.key.key);
                attributes.push(element.models.display_key);
                var where_ = {}
                var result;
                if( element['name']== "service_name"){
                    // console.log("element",element)
                    let {data} = await this.$apollo.query({
                    query: DataServices.getList("service", {"fields" : "id name"}),
                    variables: {
                        where_key : {
                        deleted : {_eq: false},
                        parent_id : {_is_null: true}
                        },
                        orderBy:[{ordinal: 'desc'},{created_at : 'desc'}]
                    }
                    })
                    if (data) {
                    result = data[Object.keys(data)];
                    if (result) {
                        for (let index = 0; index < result.length; index++) {
                        const ele = result[index];
                        optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                        }
                    }
                    }
                }else{
                    let {data} = await this.$apollo.query({
                    query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
                    variables: {where_key : where_}
                    })
                    if (data) {
                    result = data[Object.keys(data)];
                    if (result) {
                        for (let index = 0; index < result.length; index++) {
                        const ele = result[index];
                        optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                        }
                    }
                    }
                }
                // console.log("optionsData",optionsData)
                this.options[element.key] = optionsData;
                }
                this.componentKey++;
            }
        },
        DataOnChange(data,f){
            if(f.type == 'Checkbox'){
                let dataUpdate = {}
                if(f.key == 'active'){
                dataUpdate['active'] = data[f.key]
                }
                let variables = {
                'data_update': dataUpdate,
                'where_key': {
                    'id': {'_eq': data.id}
                }
                };
                this.$apollo.mutate({
                mutation: DataServices.updateData(this.modelName),
                variables: variables
                }).then(() => {})
            }
        },
        onChange() {
            this.$store.commit('setLoading', true);
            this.$apollo.queries.listData.refresh()
        },
        getFilterOptions(name) {
            var field = this.dataModel.getField(name)
            var result = []
            if (field.type == 'Selection') {
                if (field.options ) result = field.options;
                else if(field.models && this.options[name]) result = this.options[name];
            } else {
                result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
            }
            return result;
        },
        getRowActions() {
            var actions = {}
            return actions
        },
        async onActionClicked(payload) {
            var action = payload.action
            var data = null
            if (payload.data) {
                data = payload.data
            }
            var pageTransaction = (new Date()).getTime();
            switch (action.name) {
                case 'view':
                this.dialogMode = action.name
                if (action.path !== undefined && action.path) {
                    this.$router.push({ path: action.path + data.data[action.key] })
                } else  {
                    this.editId = data.data[action.key]
                    this.displayDialog = true
                    this.editDialogKey++
                }
                break
                case 'edit':
                this.dialogMode = action.name
                if (action.path !== undefined && action.path) {
                    localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
                    this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
                } else  {
                    this.editId = data.data[action.key]
                    this.displayDialog = true
                    this.editDialogKey++
                }
                break
                case 'delete':
                localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
                this.page_transaction = pageTransaction;
                this.deleteId = data.data[action.key]
                this.deleteName = data.data.name
                this.showDeleteDialog = true
                break
                case 'reexamine':
                this.dialogMode = action.name
                if (action.path !== undefined && action.path) {
                    this.$router.push({ path: action.path + data.data[action.key],query: { reexamine: true }  })
                } else  {
                    this.addId = data.data[action.key]
                    this.displayDialog = true
                    this.editDialogKey++
                }
                break
                case 'add':
                this.dialogMode = action.name
                if (action.path !== undefined && action.path) {
                    this.$router.push({ path: action.path })
                } else  {
                    this.displayDialog = true
                    this.editDialogKey++
                }
                break
                case 'custom':
                await action.handler(data.data, this)
                this.componentKey++
                break
            }
        },
        
        async loadData() {
            if (this.$commonFuction.isEmptyObject(this.filters)) {
                return;
            }
            this.loading = true;
            const headers = {
                "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
            };
            ApiRepository.post("_api/common/dashboard_order", {filters: this.filters}, {headers}).then(async (res) => {
                console.log('=========> submit booking: ', res);
                this.reportBlock = res?.data?.data?.data?.reportBlock || this.reportBlock;
                this.funnelSeriesData = res?.data?.data?.data?.funnelSeriesData || this.funnelSeriesDataTmp;
                this.columnSeriesData = this.columnSeriesDataTmp;
                if (res?.data?.data?.data?.columnSeriesData) {
                    var columnD = res?.data?.data?.data?.columnSeriesData[0][1].map((m) => { return Number(m).toFixed(0); });
                    this.columnSeriesData = res?.data?.data?.data?.columnSeriesData ?
                    [{
                        name: 'Số lượng',
                        type: 'column',
                        data: columnD
                    }, {
                        name: 'Khung thời gian',
                        type: 'line',
                        data: res?.data?.data?.data?.columnSeriesData[1]
                    }]
                    : this.columnSeriesDataTmp;
                }
                console.log("this.columnSeriesDatathis.columnSeriesDatathis.columnSeriesData", res?.data?.data?.data?.columnSeriesData)
                this.columnLabelData = res?.data?.data?.data?.columnSeriesData[0][0] || this.columnLabelDataTmp
                this.loading = false;
            }).catch(async error => {
                console.log('error', error);
                this.$toast.add({
                    severity: 'error',
                    summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                    life: 3000
                });
                this.loading = false;
            });
        }
    }
}
</script>
<style lang="scss">
.report-box {
    background: #e395b61a;
    border: 1px solid #e395b61a;
    height: 80px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .report-box-title {
        font-size: 16px;
        color: #333333;
    }
    
    .report-box-value {
        color: #C40380;
        font-size: 24px;
        font-weight: bold;
    }
}
.manager-title {
    font-size: 24px;
}

.dashboard-load-div {
    opacity: .5;
    .p-progress-spinner {
        width: 30px;
        height: 30px;
    }
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #C40380;
    }
    40% {
        stroke: #C40380;
    }
    66% {
        stroke: #C40380;
    }
    80%,
    90% {
        stroke: #C40380;
    }
}
.dashboard--view .vs--single .vs__selected {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
    display: block;
    align-self: center;
}
@media (max-width: 1240px) {
    .dashboard--view .vs--single .vs__selected {
        width: 150px;
    }
}
</style>