<template>
    <div id="chart-funnel" class="row">
        <div class="col-12">
            <!-- <span style="font-size: 18px;font-weight: bold;">THEO DÕI CHUNG TÌNH TRẠNG ĐƠN HÀNG</span>
        </div>
        <div class="col-5 d-flex">
            <div class="d-flex report-funnel-title">
                <div class="" v-for="(item, index) in funnelData?.series[0]?.data" :key="index">
                    {{ item.x }}
                </div>
            </div>
        </div> -->
        <!-- <div class="col-7"> -->
            <apexchart type="bar" height="550" ref="funnelChart" :options="funnelOptionsData" :series="funnelSeriesData"></apexchart>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
    props: {
        funnelSeriesData: Array,
        funnelOptionsData: Object
    },
    components: {
        apexchart: VueApexCharts,
    },
    watch: {
        "$props.funnelSeriesData"() {   
            var updateCategories = []
            // this.$refs.funnelChart.updateSeries(this.funnelSeriesData,true);
            this.funnelSeriesData[0].data.map(m => {
                updateCategories.push(m.x);
            })
            var funnelOptionsData = {...this.funnelOptionsData, ...{
                xaxis: {
                    categories: updateCategories
                }
            }}
            this.$refs.funnelChart.updateOptions(funnelOptionsData, false ,true);
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="scss">
.report-funnel-title {
    flex-direction: column;
    justify-content: space-around;
    font-size: 16px;
    text-align: right;
    height: 100%;
    padding: 35px 0;
}
.report-funnel {
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 10px;
    text-align: right;
}
</style>