<template>
    <div class="row">
        <div class="col-12 col-lg-6 w-100 overflow-auto" style="overflow-y: hidden !important;">
            <FunnelChar :funnelOptionsData.sync="funnelOptionsData" :funnelSeriesData.sync="funnelSeriesData"/>
        </div>
        <div class="col-12 col-lg-6 w-100 overflow-auto" style="overflow-y: hidden !important;">
            <ColumnLineChar :columnSeriesData.sync="columnSeriesData" :columnOptionsData.sync="columnOptionsData" />
        </div>
    </div>
</template>

<script>
import FunnelChar from './charts/funnel';
import ColumnLineChar from './charts/column_line';
export default {
    name: 'OperationReport',
    props: {
        loading: Boolean,
        funnelData: Array,
        columnData: Array,
        columnLabel: Array
    },
    components: {
        FunnelChar,
        ColumnLineChar,
    },
    watch: {
        'loading'() {
            this.loadData();
        }
    },
    methods: {
        loadData() {
            var columnSeriesData =  this.columnData;
            var funnelSeriesData = this.funnelData;
            this.$set(this.columnOptionsData, 'labels', this.columnLabel);
            this.$set(this, 'columnOptionsData', this.columnOptionsData);
            if (this.loading) {
                funnelSeriesData = [{data: [{x: 'Đang tải', y: 0}, {x: 'Đang tải', y: 0},  {x: 'Đang tải', y: 0}, {x: 'Đang tải', y: 0}, {x: 'Đang tải', y: 0}, {x: 'Đang tải', y: 0}, {x: 'Đang tải', y: 0}, {x: 'Đang tải', y: 0, }]}];

                columnSeriesData = [{
                    name: 'Số lượng',
                    type: 'column',
                    data: [0, 0, 0, 0, 0, 0 ,0, 0, 0, 0, 0, 0]
                }, {
                    name: 'Khung thời gian',
                    type: 'line',
                    data: [0, 0, 0, 0, 0, 0 ,0, 0, 0, 0, 0, 0]
                }];
            
                this.$set(this, 'funnelSeriesData', funnelSeriesData);

                this.$set(this, 'columnSeriesData', columnSeriesData);
                var funnelOptionsData = {...this.funnelOptionsData, ...{
                    xaxis: {
                        categories: []
                    }
                }}
                this.$set(this, 'funnelOptionsData', funnelOptionsData);
            } else {
                this.$set(this, 'funnelSeriesData', funnelSeriesData);

                this.$set(this, 'columnSeriesData', columnSeriesData);
            }
            // this.$commonFuction.sleep(2000);
            // var classCheck = Array.from(document.querySelectorAll('.lemay-custom-label'));
            // classCheck.map(m => {
            //     if (m.textContent.length > 30) {
            //         m.style.fontSize = '11px';
            //     }
            //     console.log(m.textContent.length);
            // })
        }
    },
    data() {
        return {
            loadFunnel: false,
            columnSeriesData: [{
                name: 'Số lượng',
                type: 'column',
                data: this.loading ? [ 0, 0, 0, 0, 0, 0 ,0, 0, 195, 170, 150, 120] : [195, 170, 150, 120, 0, 0, 0, 0, 0, 0 ,0, 0]
            }, {
                name: 'Khung thời gian',
                type: 'line',
                data: this.loading ? [100, 100, 100, 100, 100, 100, 100, 100, 24, 30, 45, 65] : [24, 30, 45, 65, 100, 100, 100, 100, 100, 100, 100, 100]
            }],
            columnOptionsData: {
                noData: {
                    text: 'Chưa có dữ liệu',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                annotations: {
                    yaxis: [{
                        y: 0,
                        y2: null,
                        strokeDashArray: 1,
                        borderColor: '#c2c2c2',
                        fillColor: '#c2c2c2',
                        opacity: 0.3,
                        offsetX: 0,
                        offsetY: -3,
                        width: '100%',
                        yAxisIndex: 0,
                        label: {
                            borderColor: '#c2c2c2',
                            borderWidth: 1,
                            borderRadius: 2,
                            text: undefined,
                            textAnchor: 'end',
                            position: 'right',
                            offsetX: 0,
                            offsetY: 0,
                            mouseEnter: undefined,
                            mouseLeave: undefined,
                            click: undefined,
                            style: {
                                background: '#fff',
                                color: '#777',
                                fontSize: '12px',
                                fontWeight: 400,
                                fontFamily: undefined,
                                cssClass: 'apexcharts-yaxis-annotation-label',
                                padding: {
                                    left: 5,
                                    right: 5,
                                    top: 0,
                                    bottom: 2,
                                }
                            },
                        },
                    }],
                },
                chart: {
                    width: !this.loading ? '100%' : '0px',
                    type: 'line',
                    toolbar: { show: false },
                    // events: {
                    //     mounted: (chart) => {
                    //     chart.windowResizeHandler();
                    //     }
                    // }
                },
                stroke: {
                    // curve: 'stepline',
                    width: [0, 3]
                },
                title: {
                    text: 'BÁO CÁO BIÊN BẢN GIAO HÀNG',
                    style: {
                        fontSize:  '18px',
                        fontWeight:  'bold',
                        color:  '#1D3118'
                    },
                },
                dataLabels: {
                    enabled: true
                },
                labels: this.columnLabel,
                colors: ["#66BB6B", "#FFA726"],
                xaxis: {
                    type: 'category',
                    labels: {
                        rotate: 0,
                        hideOverlappingLabels: false,
                        showDuplicates: false,
                        // trim: true,
                        // style: {
                        //     cssClass: 'break-text-css'
                        // }
                    },
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 1,
                        opacity: 0.4,
                    },
                },
                plotOptions: {
                    width: !this.loading ? '100%' : '0px',
                    bar: {
                        columnWidth: '40%',
                        columnHeight: '100%',
                    },
                    // events: {
                    //     mounted: (chart) => {
                    //     chart.windowResizeHandler();
                    //     }
                    // }
                },
                yaxis: [{
                    title: {
                        text: undefined
                    },
                    labels: {
                        show: true,
                        align: 'right',
                        maxWidth: 500,
                        offsetX: 0,
                        formatter: function (val) {
                            return Math.round(val*1);
                        }
                    },
                }, {
                    opposite: true,
                    title: {
                        text: undefined
                    },
                    tickAmount: 4,
                    min: 0,
                    max: 100,
                    labels: {
                        formatter: (value) => { return value + "%" },
                    }
                }],
                legend: {
                    show: false
                },
                grid: {
                    show: true,
                    strokeDashArray: 4,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                tooltip: {
                    enabled: false
                }
            },
            funnelSeriesData: this.funnelData,
            funnelOptionsData: {
                noData: {
                    text: 'Chưa có dữ liệu',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '20px',
                        fontFamily: undefined
                    }
                },
                chart: {
                    type: 'bar',
                    brush: {
                        enabled: false,
                    },
                    toolbar: { show: false }
                    // events: {
                    //     mounted: (chart) => {
                    //     chart.windowResizeHandler();
                    //     }
                    // }
                },
                title: {
                    text: 'THEO DÕI CHUNG TÌNH TRẠNG ĐƠN HÀNG',
                    // text: undefined,
                    align: 'left',
                    style: {
                        fontSize:  '18px',
                        fontWeight:  'bold',
                        color:  '#1D3118'
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: true,
                        rangeBarOverlap: false,
                        barHeight: '40%',
                        columnWidth: '40%',
                        isFunnel: true,
                        isFunnel3d: false,
                        // rangeBarGroupRows: true,

                    },
                },
                fill: {
                    type: 'solid',
                },
                colors: [
                    '#42A5F5'
                ],
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '16px',
                        
                    },
                    // offsetY: 5,
                    formatter: function (val) {
                        return val;
                    },
                    // , { seriesIndex, dataPointIndex, w }
                    // w.config.series[seriesIndex].data[dataPointIndex].x + ":  " + 
                    dropShadow: {
                        enabled: true,
                    },
                },
                xaxis: {
                    type: 'categories',
                    categories: ['Tổng đơn xuất Kho', 'Nhận đơn hàng và bắt đầu soạn hàng', 'Niêm phong kiện và chuyển khu chờ xuất', 'Điều phối giao hàng', 'Xuất hàng thực tế', 'Đang giao hàng', 'Đã giao hàng', 'Giao thành công & Đủ bộ HSCT'],
                    tickPlacement: 'on',
                    position: 'bottom',
                    labels: {
                        show: false,
                        formatter: function (val, opts) {
                            if (opts?.seriesIndex && opts?.dataPointIndex && opts?.w ) {
                                return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x + ":  " + val;
                            }
                            return val;
                        },
                    },
                },
                yaxis: {
                    show: true,
                    showAlways: true,
                    showForNullSeries: true,
                    axisTicks: {
                        show: true,
                    },
                    labels: {
                        show: true,
                        align: 'right',
                        maxWidth: 500,
                        offsetX: 0,
                        // formatter: function (val, index) {
                        //     return val;
                        // },
                        
                        formatter: function (val, opts) {
                            if (opts?.seriesIndex && opts?.dataPointIndex && opts?.w ) {
                                return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x + ":  " + val;
                            }
                            return val;
                        },
                        style: {
                            fontSize: "18px",
                            // fontWeight: 'bold',
                            cssClass: 'lemay-custom-label',
                        },
                    },
                },
                grid: {
                    show: true,
                    strokeDashArray: 4,
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },   
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                }
            },
        }
    },
}
</script>

<style lang="scss">
@media (max-width: 900px) {
    #chart-funnel, #chart-column {
        width: 1000px;
    }   
}
</style>